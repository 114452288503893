import React, { useEffect, useState } from "react";

export const addToCart = (itemProduct: any, quantity: number, comboList: any, installationService: any) => {

    let cartData: any = null;
    let cartItems: any[] = [];
    const custId = sessionStorage.getItem("customerId") ? Number(sessionStorage.getItem("customerId")) : null;
    const isCustomerLoggedIn = custId !== null;

    let sessionId = null;
    if (!isCustomerLoggedIn) {
        return false;
    }

    const savedCart = sessionStorage.getItem("cart");
    if (savedCart) {
        cartData = JSON.parse(savedCart);
        cartItems = cartData.cartItems;
    }

    if (itemProduct) {
        let existingItem: any = null;
        let isExistingItem: boolean = false;
        if (cartItems && cartItems.length > 0) {
            existingItem = cartItems.find((x: any) => x.product.productId == itemProduct.productId);

        }
        if (existingItem) {
            isExistingItem = true;
            let newQuantity = existingItem.quantity + quantity;
            let itemPrice = (itemProduct.mrpPrice + (itemProduct.mrpPrice * itemProduct.taxPercent) / 100);
            existingItem = {
                ...existingItem,
                "quantity": newQuantity,
                "totalPrice": itemPrice * newQuantity
            }
        } else {
            let itemPrice = (itemProduct.mrpPrice + (itemProduct.mrpPrice * itemProduct.taxPercent) / 100);
            existingItem = {
                product: {
                    productId: itemProduct.productId,
                    productName: itemProduct.productName,
                    salePrice: itemProduct.salePrice,
                },
                quantity: quantity,
                unitPrice: itemProduct.mrpPrice,
                totalPrice: itemPrice * quantity,
            }
        }

        let newCartItems: any = [existingItem];

        if (comboList && comboList.length > 0) {
            newCartItems = [...newCartItems, ...comboList];
        }

        if (installationService) {
            newCartItems = [...newCartItems, installationService];
        }

        if (cartItems && cartItems.length > 0) {
            let updatedItems: any[] = [...cartItems];
            if (isExistingItem) {
                let index = updatedItems.findIndex((x) => {
                    return x.product.productId === existingItem.product.productId;
                });
                updatedItems[index] = { ...existingItem };
            } else {
                updatedItems = [...updatedItems, ...newCartItems];
            }

            let updatedCartData = { ...cartData, "cartItems": updatedItems };
            sessionStorage.setItem("cart", JSON.stringify(updatedCartData));
        } else {
            const addcart: any = {
                sessionId: sessionId,
                cartItems: newCartItems,
                customerId: custId,
            };

            sessionStorage.setItem("cart", JSON.stringify(addcart));
        }
        return true;
    }
};
