import React, { useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";

declare global {
    interface Window {
        Razorpay: any;
    }
}

interface RazorpayOptions {
    key: string;
    amount: number;
    currency: string;
    name: string;
    description?: string;
    order_id: string;
    handler: (response: {
        razorpay_payment_id: string;
        razorpay_order_id: string;
        razorpay_signature: string;
    }) => void;
    prefill?: {
        name?: string;
        email?: string;
        contact?: string;
    };
    notes?: { [key: string]: string };
    theme?: {
        color?: string;
    };
}


const RazorpayPayment: React.FC<any> = ({ anount }) => {
    const [paymentData, setPaymentData] = useState<any>(null);
    const createOrder = async () => {
        const orderUrl = 'https://api.razorpay.com/v1/orders';
        const orderData = {
            amount: 50000,
            currency: 'INR',
            receipt: 'order_rcptid_11',
        };

        try {
            const response = await axios.post(orderUrl, orderData, {
                auth: {
                    username: 'rzp_test_qHpBRBwDfZ5fxZ',
                    password: 'TE0hOgMUovyV93XorR9D4Th4',
                },
            });
            setPaymentData(response.data);
            handlePayment();
        } catch (error) {
            console.error('Error creating order:', error);
        }
    };
    const handlePayment = async () => {
        try {
            // Request the backend to create an order
            // const { data: order } = await axios.post("/api/payment/create-order", {
            //     amount: 500, // Amount in INR
            // });

            // Initialize Razorpay
            const razorpayOptions = {
                key: 'rzp_test_qHpBRBwDfZ5fxZTE0hOgMUovyV93XorR9D4Th4',
                amount: (paymentData && paymentData.amount) ? paymentData.amount : 50000,
                currency: (paymentData && paymentData.currency) ? paymentData.currency : "INR",
                name: 'Credenze',
                description: 'Ecommerce',
                image: 'https://your-logo-url.com',
                order_id: (paymentData && paymentData.id) ? paymentData.id : "order_OkkD22VfHlbYxc",
                handler: function (response: any) {
                    //console.log(response);
                },
                prefill: {
                    name: 'Rockstar',
                    email: 'rockstar.king@example.com',
                    contact: '9898989898',
                },
                theme: {
                    color: '#DC2626',
                },
            };

            const razorpay = new window.Razorpay(razorpayOptions);
            razorpay.open();
        } catch (error) {
            console.error(error);
            alert("Payment Failed");
        }
    };

    return <Button onClick={handlePayment} > Pay Now </Button>;
};

export default RazorpayPayment;
