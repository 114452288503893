import React, { useEffect, useState } from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentIcon from '@mui/icons-material/Payment';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Rating,
  Chip,
  IconButton,
  Card,
  CardContent,
  Paper,
  List,
  ListItem,
  ListItemText,
  CardActions,
  Avatar,
  CardMedia,
  Divider,
  LinearProgress,
  Tab,
  Tabs,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Input,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import YourOrder from "../../components/yourOrder/yourOrder";
import DetailPageSide from "../../components/detailPageSideBar/detailPageSide";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import StarIcon from "@mui/icons-material/Star";
import Header from "../../components/header/header";
import "./ProductDetail.css";
import { makeStyles } from "@mui/styles";
import Footer from "../../components/footer/footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_BY_PINCODE,
  GET_PRODUCT_PROMOTIONS,
  GET_PRODUCT_REVIEWS_BY_PRODUCT,
  PRODUCT_REVIEW,
} from "../../redux/apis";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const rowStyle = {
  border: '1px solid #ddd',
};

const cellStyle = { padding: '10px', textAlign: 'left' };

const useStyles = makeStyles({
  mainPart: {
    // paddingHorizontal: WIDTH / 100 * 5,
    backgroundColor: "transparent",
    width: (WIDTH * 90) / 100,
    // height:HEIGHT/100*85,
    // paddingVertical: HEIGHT / 100 * 1,
  },
  titleText: {
    color: "#000000",
    fontSize: 20,
    // fontFamily: FAMILY.SansProSemibold,
    marginBottom: (HEIGHT / 100) * 1,
  },
  container: {
    height: (HEIGHT / 100) * 33,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  headLine: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  catBox: {
    height: (HEIGHT / 100) * 11.5,
    width: (HEIGHT / 100) * 11.5,
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: (WIDTH / 100) * 2,
  },
  catBox1: {
    height: (HEIGHT / 100) * 11.5,
    width: (HEIGHT / 100) * 11.5,
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 8,
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: (WIDTH / 100) * 5,
  },
  catText: {
    color: "#030712",
    fontSize: 10,
    // fontFamily: FAMILY.SansProRegular,
    textAlign: "center",
    height: 40,
    textAlignVertical: "center",
    lineHeight: 12,
  },
  BestBox: {
    // height: HEIGHT / 100 * 25,
    width: WIDTH < 400 ? (WIDTH * 60) / 100 : (WIDTH / 100) * 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#C4C4C4",
    padding: (WIDTH / 100) * 1,
    marginRight: (WIDTH / 100) * 2,
    flexDirection: "row",
    backgroundColor: "white",
  },
  BestBoxs: {
    // height: HEIGHT / 100 * 25,
    width: (WIDTH / 100) * 90,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#C4C4C4",
    // padding: WIDTH / 100 * 1,
    marginRight: (WIDTH / 100) * 2,
    flexDirection: "row",
    backgroundColor: "white",
  },
  bestText: {
    color: "#2B2B2D",
    fontSize: 15,
    fontWeight: "600",
    // fontFamily: FAMILY.SansProRegular
  },
  bestText1: {
    marginBottom: (HEIGHT * 2) / 100,
    color: "#ADADAD",
    fontSize: 13,
    fontWeight: "400",
  },
  orgText: {
    color: "#F53E32",
    fontSize: 16,
    fontWeight: "600",
    // fontFamily: FAMILY.SansProSemibold
  },
  crsText: {
    color: "#7A7A7A",
    fontSize: 13,
    // fontFamily: FAMILY.SansProRegular,
    marginLeft: (WIDTH / 100) * 1,
    textDecorationLine: "line-through",
  },
  starCount: {
    fontSize: 11,
    // fontFamily: FAMILY.PoppinsRegular,
    color: "#999999",
  },
  buyBtn: {
    height: (HEIGHT / 100) * 4,
    width: "50%",
    backgroundColor: "#278BED",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  buyText: {
    fontSize: 13,
    textAlign: "center",
    fontWeight: "600",
    // fontFamily: FAMILY.SansProSemibold,
    color: "#FFFFFF",
  },
  offPart: {
    height: (HEIGHT / 100) * 37,
    width: (WIDTH / 100) * 15,
    // padding:WIDTH/100*3,
    marginRight: (WIDTH / 100) * 2,
    justifyContent: "space-evenly",
  },
  dealTitle: {
    color: "#DC2626",
    fontSize: 28,
    // fontFamily: FAMILY.SansProLight
  },
  dealTitle1: {
    color: "#8B96A5",
    fontSize: 16,
    // fontFamily: FAMILY.SansProRegular,
  },
  dayText: {
    // fontFamily: FAMILY.SansProBold,
    fontSize: 16,
    color: "#FFFFFF",
  },
  dayText1: {
    // fontFamily: FAMILY.SansProRegular,
    fontSize: 12,
    color: "#FFFFFF",
  },
  dayImgBack: {
    height: (WIDTH / 100) * 2,
    width: (WIDTH * 3.75) / 100,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 15,
    paddingBottom: 15,
    marginRight: (WIDTH / 100) * 0.5,
    marginTop: (HEIGHT / 100) * 0.5,
    marginBottom: (HEIGHT / 100) * 0.5,
    backgroundColor: "red",
    display: "flex",
  },
});

const ProductDetail: React.FC = () => {
  const styles = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const [data, setData] = useState<any>();
  const [productId, setProductId] = useState<any>();
  const [image1, setImage1] = useState([
    require("../../../assets/first.png"),
    require("../../../assets/second.png"),
    require("../../../assets/third.png"),
    require("../../../assets/fourth.png"),
  ]);
  const [pincode, setPincode] = useState("");
  const [pinCodeData, setPinCodeData] = useState<any>();
  const [ind, setInd] = useState(0);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [value, setValue] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [existingReview, setExistingReview] = useState(null);
  const [promotions, setPromotions] = useState();

  const accessories = [
    {
      title: "1 4 Channel HDTVI DVR for All Types CCTV Camera",
      price: "₹2000",
      oldPrice: "₹5000",
      rating: 4.5,
      image: "https://via.placeholder.com/150",
      label: "NEW",
    },
    {
      title: "Protokraft 4 Channel Aluminium CCTV Camera Transceiver",
      price: "₹2000",
      oldPrice: "₹5000",
      rating: 4.5,
      image: "https://via.placeholder.com/150",
      label: "HOT",
    },
    {
      title: "BNC Connector and DC Pin with Copper Wire Molded Combo Pack",
      price: "₹2000",
      oldPrice: "₹5000",
      rating: 4.5,
      image: "https://via.placeholder.com/150",
      label: "HOT",
    },
    {
      title: "CCTV Camera with 4 Channel DVR, 1TB Hard Disk & 70 m Wire Kit",
      price: "₹2000",
      oldPrice: "₹5000",
      rating: 4.5,
      image: "https://via.placeholder.com/150",
      label: "HOT",
    },
    {
      title: "Multicamera Video Switcher with 4 x HDMI Inputs",
      price: "₹2000",
      oldPrice: "₹5000",
      rating: 4.5,
      image: "https://via.placeholder.com/150",
      label: "HOT",
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setTabIndex(newValue);
  };

  const handleRatingChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (location.state) {
      setData(null);
      setProductId(location.state);
      getProducts(location.state);
      getProductReviews(location.state);
    }
  }, [location.state]);

  const getProducts = async (val: any) => {
    const response = await fetch(`${GET_PRODUCT_BY_ID}${val}`);
    const datas = await response.json();
    setData(datas);
    setSelectedImage((datas && datas.productImageUrl) ? datas.productImageUrl :
      (datas.productImages && datas.productImages.length > 0) ? datas.productImages[0].imageUrl :
        require("../../../assets/no_image.png"));
    getPromotions(datas.productId, datas.category.categoryId);
  };

  const [totalRating, setTotalRating] = useState<any>();
  const [averageRating, setAverageRating] = useState<any>();
  const [reviews, setReviews] = useState<any>([]);
  const [ratingCounts, setRatingCounts] = useState<any>();
  const [ratingPercentages, setRatingPercentages] = useState<any>();
  const getProductReviews = async (val: any) => {
    if (val) {
      const id = val.toString();
      try {
        const response = await fetch(`${GET_PRODUCT_REVIEWS_BY_PRODUCT}${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const text = await response.text();
        if (text) {
          try {
            const data = JSON.parse(text);
            const customerId = sessionStorage.getItem("customerId");
            setReviews(data ? data.ratings : []);
            setTotalRating(data ? data.totalRatings : 0);
            setAverageRating(data ? data.averageRating : 0);
            const ratingCounts = [5, 4, 3, 2, 1].map(rating =>
              reviews.filter((review: any) => review.rating === rating).length
            );
            setRatingCounts(ratingCounts);
            const ratingPercentages = ratingCounts.map(count => (Math.round((count / data.totalRatings) * 100)));
            setRatingPercentages(ratingPercentages);
          } catch (error) {
            console.error("Invalid JSON:", error);
          }
        } else {
          console.warn("Empty response");
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }
  };

  const formatDate = (date: any) => {
    return new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(new Date(date));
  };

  const getPromotions = async (productId: any, categoryId: any) => {
    if (data) {
      try {
        const response = await fetch(GET_PRODUCT_PROMOTIONS + "productId=" + productId + "&categoryId=" + categoryId);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const text = await response.text();
        if (text) {
          try {
            const data = JSON.parse(text);
            setPromotions(data);
          } catch (error) {
            console.error("Invalid JSON:", error);
          }
        } else {
          console.warn("Empty response");
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }
  };

  const postReviews = async () => {
    const token = sessionStorage.getItem("token");
    const custId = sessionStorage.getItem("customerId");

    let params = {
      productId: data?.productId,
      customerId: custId,
      rating: value,
      reviewHeadline: reviewText,
      reviewText: reviewText,
    };

    if (existingReview) {
      // If review exists, update it
      await fetch(`${PRODUCT_REVIEW}/${existingReview}`, {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          //console.log(result, "Product review updated successfully");
        })
        .catch((error) => console.error(error));
    } else {
      // If no review exists, create a new one
      await fetch(PRODUCT_REVIEW, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          //console.log(result, "Product review added successfully");
        })
        .catch((error) => console.error(error));
    }
  };

  const getProdByPincode = async () => {
    await fetch(
      `${GET_PRODUCT_BY_PINCODE}${data?.productId}&pinCode=${pincode}`,
      {
        method: "GET",
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        if (text) {
          try {
            return JSON.parse(text);
          } catch (error) {
            console.error("Invalid JSON:", error);
            return null;
          }
        } else {
          console.warn("Empty response");
          return null;
        }
      })
      .then((data) => {
        if (data) {
          setPinCodeData(data);
        } else {
          console.log("No data returned");
        }
      })
      .catch((error) => console.error("Fetch error:", error));
  };

  return (
    <>
      <Header />
      {/* width: (WIDTH * 90) / 100,  */}
      <Box style={{ marginLeft: (WIDTH * 5) / 100 }}>
        <Box display={"flex"} flexDirection={"row"}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Paper sx={{ p: 4 }} style={{ borderRadius: 10 }}>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ position: "relative", }}>
                      <img
                        src={selectedImage}
                        alt="Main Product"
                        style={{ width: "100%", borderRadius: 4 }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "white",
                        }}
                      >
                        <FavoriteBorderIcon />
                      </IconButton>
                      <Grid
                        container
                        spacing={1}
                        sx={{ mt: 2 }}
                        style={{ alignItems: "center" }}
                      >
                        <Grid item>
                          <IconButton>
                            <ArrowBackIosIcon />
                          </IconButton>
                        </Grid>
                        <Box
                          sx={{
                            display: "flex",
                            overflowX: "auto", // Enable horizontal scrolling
                            gap: 2, // Spacing between grid items
                            "&::-webkit-scrollbar": {
                              height: "8px", // Optional: Custom scrollbar styling
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "10px",
                            },
                          }}
                        >
                          {data &&
                            data.productImages &&
                            data.productImages.length != 0
                            ? data.productImages.map((item: any, index: any) => (
                              <Grid
                                item
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  setInd(index);
                                  setSelectedImage(item.imageUrl);
                                }}
                              >
                                <img
                                  src={data.productImages[index].imageUrl}
                                  alt={`Thumbnail ${index + 1}`}
                                  style={{
                                    width: 50,
                                    height: 40,
                                    borderRadius: 4,
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </Grid>
                            ))
                            : null}
                        </Box>
                        <Grid item>
                          <IconButton>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <CardContent>
                        <Typography variant="body2">
                          Credenze Benefits
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          {[
                            {
                              title: "GST Invoice Available",
                              images: require("../../../assets/money.png"),
                            },
                            {
                              title: "Secure Payments",
                              images: require("../../../assets/shielding.png"),
                            },
                            {
                              title: "365 Days Help Desk",
                              images: require("../../../assets/timer.png"),
                            },
                          ].map((benefit, index) => (
                            <ListItem
                              style={{
                                marginLeft: "3px",
                                borderWidth: 1,
                                borderColor: "#DDDDDD",
                              }}
                              key={index}
                            >
                              <img
                                src={benefit.images}
                                alt="Product"
                                style={{ marginRight: "5px" }}
                              />
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    fontSize: "10px",
                                  },
                                }}
                                primary={benefit.title}
                              />
                            </ListItem>
                          ))}
                        </List>

                        <Typography variant="body2">
                          Return & Warranty Policy
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          {[
                            {
                              title: "7 Days Brand Replacement",
                              images: require("../../../assets/replace.png"),
                            },
                            {
                              title: "Missing Product",
                              images: require("../../../assets/missing.png"),
                            },
                            {
                              title: "Wrong Product",
                              images: require("../../../assets/wrong.png"),
                            },
                          ].map((policy, index) => (
                            <ListItem key={index}>
                              <img
                                src={policy.images}
                                alt="Product"
                                style={{ marginRight: "5px" }}
                              />
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    fontSize: "10px",
                                  },
                                }}
                                primary={policy.title}
                              />
                            </ListItem>
                          ))}
                        </List>

                        <List
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                          }}
                        >
                          {[
                            {
                              title: "Damaged Product",
                              images: require("../../../assets/damage.png"),
                            },
                          ].map((policy, index) => (
                            <ListItem key={index}>
                              <img
                                src={policy.images}
                                alt="Product"
                                style={{ marginRight: "5px" }}
                              />
                              <ListItemText
                                sx={{
                                  "& .MuiListItemText-primary": {
                                    fontSize: "10px",
                                  },
                                }}
                                primary={policy.title}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ alignItems: "center" }}>
                      <Chip
                        label="Sale Off"
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "#FDE0E9",
                          color: "#F74B81",
                        }}
                      />
                      <Typography
                        variant="h5"
                        component="h2"
                        sx={{ fontWeight: "bold", color: "black" }}
                      >
                        {data && data.displayName
                          ? data.displayName
                          : "8MP Outdoor AI Surveillance Camera"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <Rating value={averageRating ? averageRating : 0} readOnly precision={0.5} />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        ({totalRating ? totalRating : 0} reviews)
                      </Typography>
                    </Box>
                    {/* <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                      Colour
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#F74B81",
                          borderColor: "#F74B81",
                          borderWidth: 1,
                        }}
                      >
                        White
                      </Button>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#8A8A8A",
                          borderColor: "#8A8A8A",
                          borderWidth: 1,
                        }}
                      >
                        Black
                      </Button>
                    </Box> */}
                    <Typography component="h3" sx={{ mt: 2 }} style={{
                      fontSize: '14px'
                    }}>
                      Check Service availability at your Pin code
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        mt: 1,
                        borderColor: "#DDDDDD",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 3,
                      }}
                      border={1}
                      className="fits"
                    >
                      <img
                        src={require("../../../assets/locs.png")}
                        alt="Main Product"
                        style={{
                          width: "15px",
                          height: "20px",
                          marginLeft: "10px",
                        }}
                      />
                      <TextField
                        sx={{
                          width: WIDTH < 400 ? "auto" : "auto",
                          alignItems: "center",
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            height: "15px",
                          },
                        }}
                        value={pincode}
                        onChange={(event) => {
                          setPincode(event.target.value);
                          setPinCodeData(null);
                        }}
                        label="Enter Your Pin Code"
                      />
                      <Button
                        onClick={getProdByPincode}
                        variant="contained"
                        color="primary"
                        style={{ height: 38, marginRight: '5px' }}
                      >
                        CHECK
                      </Button>
                    </Box>
                    {pinCodeData ? (
                      <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={
                            pinCodeData && pinCodeData.codAvailable
                              ? {
                                width: "auto",
                                justifyContent: "flex-start",
                                backgroundColor: "#F6FFF5",
                              }
                              : {
                                width: "auto",
                                justifyContent: "flex-start",
                                backgroundColor: "#FDE0E9",
                                marginTop: (HEIGHT * 2) / 100,
                              }
                          }
                        >
                          <Typography
                            style={
                              pinCodeData && pinCodeData.codAvailable
                                ? {
                                  fontSize: 10,
                                  display: "block",
                                  color: "#3BB77E",
                                  fontWeight: "700",
                                }
                                : {
                                  fontSize: 10,
                                  display: "block",
                                  color: "#f74b81",
                                  fontWeight: "700",
                                }
                            }
                            className="algn1"
                          >
                            {pinCodeData && pinCodeData.codAvailable
                              ? "AVAILABLE"
                              : "NOT AVAILABLE"}
                          </Typography>
                        </Button>
                        {pinCodeData && pinCodeData.estimatedDeleivery ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              width: "auto",
                              justifyContent: "flex-start",
                              backgroundColor: "#F6FFF5",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 10,
                                display: "block",
                                color: "#3BB77E",
                                fontWeight: "700",
                              }}
                              className="algn1"
                            >
                              Available between {pinCodeData.estimatedDeleivery}
                            </Typography>
                          </Button>
                        ) : null}
                      </Box>
                    ) : null}
                    <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          width: "auto",
                          justifyContent: "flex-start",
                          backgroundColor: "#F6FFF5",
                        }}
                      >
                        <img
                          src={require("../../../assets/spin.png")}
                          alt="Main Product"
                          style={{ width: "18%" }}
                        />
                        <Typography
                          style={{
                            fontSize: 10,
                            display: "block",
                            color: "#3BB77E",
                            fontWeight: "700",
                          }}
                          className="algn1"
                        >
                          7 Days Brand Replacement
                        </Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ backgroundColor: "#F6FFF5" }}
                      >
                        <img
                          src={require("../../../assets/badge.png")}
                          alt="Main Product"
                          style={{ width: "18%" }}
                        />
                        <Typography
                          style={{
                            fontSize: 10,
                            color: "#3BB77E",
                            fontWeight: "700",
                          }}
                          className="algn1"
                        >
                          1 Year Warranty
                        </Typography>
                      </Button>
                    </Box>
                    <Typography variant="body1" sx={{ mt: 3 }}>
                      {data && data.shortDesc
                        ? data.shortDesc
                        : `The Surveillance Camera is a state-of-the-art CCTV solution designed to provide comprehensive security
                                            monitoring for both residential and commercial properties. With its advanced features and robust
                                            construction, this camera ensures reliable performance and peace of mind.`}
                    </Typography>
                    {data &&
                      data.productKeyFeatures && <>
                        <Typography variant="h6" component="h3" sx={{ mt: 3 }}>
                          Key Features
                        </Typography>
                        <List sx={{ padding: 0 }}>
                          {data.productKeyFeatures.length != 0
                            ? data.productKeyFeatures.map(
                              (feature: any, index: any) => (
                                <ListItem key={index}>
                                  <img
                                    src={require("../../../assets/checkcircle.png")}
                                    alt="tick"
                                    style={{ marginRight: "10px" }}
                                  />
                                  <Typography variant="body2">
                                    {feature.keyFeatureText}
                                  </Typography>
                                </ListItem>
                              )
                            ) : ""}
                        </List>
                      </>
                    }

                    <Typography variant="h6" component="h3" sx={{ mt: 3 }}>
                      Offers & Coupons
                    </Typography>

                    <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
                      {[
                        "Get Flat 100 Off on CCTV Cameras",
                        "Get GST Invoice and save up to 18%",
                        "EMIs Available on min purchases of Rs. 3000",
                      ].map((offer, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mb: 1,
                          }}
                        >
                          <Typography variant="body2">{offer}</Typography>
                          <Button variant="outlined" size="small">
                            VIEW
                          </Button>
                        </Box>
                      ))}
                    </Paper>

                    <Button variant="contained" color="primary" fullWidth>
                      VIEW ALL OFFERS
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              style={{ marginTop: (-HEIGHT * 3.5) / 100, padding: 0 }}
            >
              {data && <DetailPageSide pass={data} />}
            </Grid>
          </Grid>
        </Box>

        <Box
          flexDirection={"row"}
          display={"flex"}
          style={{
            marginTop: (HEIGHT * 3) / 100,
            marginBottom: (HEIGHT * 3) / 100,
          }}
        >
          <Button
            variant="contained"
            style={{
              height: 45,
              backgroundColor: "white",
              borderRadius: 20,
              color: "#DC2626",
            }}
            onClick={() => handleTabChange("", 0)}
          >
            <strong> Description </strong>
          </Button>
          <Button
            variant="contained"
            style={{
              height: 45,
              backgroundColor: "white",
              borderRadius: 20,
              color: "#7E7E7E",
              marginLeft: "30px",
            }}
            onClick={() => handleTabChange("", 1)}
          >
            <strong>Specification</strong>
          </Button>
          <Button
            variant="contained"
            style={{
              height: 45,
              backgroundColor: "white",
              borderRadius: 20,
              color: "#7E7E7E",
              marginLeft: "30px",
            }}
            onClick={() => handleTabChange("", 2)}
          >
            <strong>Instruction</strong>
          </Button>
        </Box>

        <Card variant="outlined" style={{ borderRadius: 10 }}>
          <CardContent>
            {tabIndex === 0 && (
              <Box>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: data && data.longDesc ? data.longDesc : "" }}>
                </Typography>
              </Box>
            )}
            {tabIndex === 1 && (
              <Box sx={{ p: 2 }}>
                <Typography variant="body1">
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontWeight: "700", marginTop: "20px" }}
                  >
                    Technical Specifications
                  </Typography>
                  <table style={{ width: '75%', borderCollapse: 'collapse' }}>
                    <tbody>

                      {data &&
                        data.productSpecifications &&
                        data.productSpecifications.length !== 0 ? (
                        data.productSpecifications.map(
                          (
                            item: {
                              specificationLabelName: string;
                              specificationLabelText: string;
                            },
                            index: React.Key | null | undefined
                          ) => (
                            <tr key={index} style={rowStyle}>
                              <td style={{ padding: '10px', textAlign: 'left', fontWeight: 'bolder', width: '30%' }}>{item.specificationLabelName} : </td>
                              <td style={{ padding: '10px', textAlign: 'left', fontWeight: 'bold' }}>{item.specificationLabelText}</td>
                            </tr>
                          )
                        )
                      ) : ""}
                    </tbody>
                  </table>
                </Typography>
              </Box>
            )}

            {tabIndex === 2 && (
              <Box sx={{ p: 2 }}>
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: data && data.installationInstruction ? data.installationInstruction : "" }}>
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>

        {reviews && reviews.length > 0 && <>
          <Accordion
            sx={{ boxShadow: "none" }}
            defaultExpanded
            style={{ backgroundColor: "transparent" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography><strong>Reviews & Ratings</strong></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Card
                variant="outlined"
                style={{ borderRadius: 10, padding: (WIDTH * 3) / 100 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7} md={6}>
                    <Box display="flex">
                      <Box>
                        <Typography
                          variant="h3"
                          sx={{
                            color: "#4CAF50",
                            marginRight: (WIDTH * 0.3) / 100,
                            fontWeight: "700",
                          }}
                        >
                          {averageRating}
                        </Typography>
                        <Typography variant="body1">{totalRating} Ratings</Typography>
                      </Box>
                      <Box>
                        <Grid container spacing={1} alignItems="center">
                          {ratingCounts && ratingCounts.map((rating: any, index: number) => (
                            <>
                              <Grid item xs={1}>
                                <Typography variant="body2">{index + 1}</Typography>
                              </Grid>
                              <Grid item xs={1}>
                                <img
                                  src={require("../../../assets/greenstar.png")}
                                  alt="star"
                                  style={{}}
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <LinearProgress
                                  sx={{
                                    height: "7px",
                                    "& .MuiLinearProgress-bar": {
                                      backgroundColor: "#3BB77E",
                                    },
                                    backgroundColor: "#E8E8E8",
                                  }}
                                  variant="determinate"
                                  value={ratingPercentages[index]}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Typography variant="body2">{ratingPercentages[index]}%</Typography>
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={5} md={4}>
                  <Box style={{ justifyContent: "flex-start" }}>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      Do you own or have used the product?
                    </Typography>
                    <Input
                      onChange={(event) => {
                        setReviewText(event.target.value);
                      }}
                      style={{
                        marginTop: "10px",
                        color: "#9E9E9E",
                        width: (WIDTH * 18) / 100,
                      }}
                      placeholder="Tell your experience about this product"
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ marginTop: "10px", marginLeft: "0px" }}
                    >
                      <Rating
                        name="rating"
                        value={value}
                        precision={1}
                        onChange={handleRatingChange}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ ml: 2 }}
                      style={{
                        marginTop: "10px",
                        marginLeft: "0px",
                        backgroundColor: "#EC0000",
                      }}
                      onClick={postReviews}
                    >
                      WRITE A REVIEW
                    </Button>
                  </Box>
                </Grid> */}
                </Grid>
              </Card>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: "none" }}
            defaultExpanded
            style={{ backgroundColor: "transparent" }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography><strong>All Reviews ({totalRating})</strong></Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              {reviews && reviews.map((review: any, index: number) => (
                <Paper key={index} sx={{ p: 3, my: 2, borderRadius: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar>{review.customerName.charAt(0)}</Avatar>
                    </Grid>
                    <Grid item xs>
                      <Box display="flex" justifyContent="space-between">
                        <Typography variant="body1">
                          <strong>{review.customerName}</strong>
                        </Typography>
                        {/* <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Button
                          startIcon={<ThumbUpAltOutlinedIcon />}
                          size="small"
                        >
                          120
                        </Button>
                        <Button
                          startIcon={<ThumbDownAltOutlinedIcon />}
                          size="small"
                          sx={{ ml: 2 }}
                        >
                          20
                        </Button>
                      </Box> */}
                      </Box>
                      <Box
                        display="flex"
                        style={{ marginBottom: (HEIGHT * 0.5) / 100 }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{
                            marginRight: (WIDTH * 2) / 100,
                            color: "#EC0000",
                          }}
                        >
                          {formatDate(review.reviewDate)}
                        </Typography>
                        {/* <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ color: "#3BB77E" }}
                      >
                        Verified Purchase
                      </Typography> */}
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Rating value={review.rating} readOnly />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ ml: 1 }}
                        >
                          {review.rating}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <strong> {review.reviewHeadline}</strong> - {review.reviewText}
                  </Typography>
                </Paper>
              ))}
            </AccordionDetails>
          </Accordion>
        </>}


        <Box mt={4}>
          <Typography
            variant="h5"
            gutterBottom
            style={{ marginBottom: (HEIGHT * 3) / 100 }}
          >
            Accessories for your product
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginBottom: (HEIGHT * 10) / 100 }}
          >
            {accessories.map((accessory, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2.3}
                key={index}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    boxShadow: 2,
                  }}
                  className={styles.BestBox}
                >
                  <Box
                    style={{
                      width: "100%",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <Box
                      component="img"
                      src={require("../../../assets/Home/Like.png")}
                    />
                    <Box
                      component="img"
                      src={require("../../../assets/hot.png")}
                    />
                  </Box>
                  <Box
                    style={{
                      height: (HEIGHT / 100) * 23,
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: (HEIGHT / 100) * 1,
                      marginBottom: (HEIGHT / 100) * 1,
                      width: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      // resizeMode='contain'
                      style={{ width: "80%", height: (HEIGHT * 23) / 100 }}
                      src={require("../../../assets/Home/Cctv.png")}
                    />
                  </Box>
                  <label className={styles.bestText1}>Category</label>
                  <Box style={{}}>
                    <label className={styles.bestText}>
                      2 MP Build-in Mic Fixed Bullet Network Camera
                    </label>
                  </Box>
                  <Box
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box style={{ flexDirection: "row", alignItems: "center" }}>
                      <label className={styles.orgText}>₹2000</label>
                      <label className={styles.crsText}>₹5000</label>
                    </Box>
                    <Box sx={{ "& .MuiRating-icon": { fontSize: 10 } }}>
                      <Rating value={4} readOnly size={"small"} />
                    </Box>
                    {/* <Box component="img" src={require('../../../assets/stars.png')} /> */}
                    <Box style={{ flexDirection: "row", alignItems: "center" }}>
                      <label className={styles.starCount}>(4.5)</label>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: (HEIGHT / 100) * 1,
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <Checkbox name="antoine" {...label} />
                    <Button
                      variant='outlined'
                      color="success"
                      size="small"
                      style={{ marginRight: '0px', marginLeft: '55px' }}
                      sx={{
                        paddingLeft: '19px',
                        minWidth: '10px'
                      }}
                      aria-label='Buy Now'
                      startIcon={<PaymentIcon />}></Button>
                    <Button
                      variant='outlined'
                      color="warning"
                      size="small"
                      sx={{
                        paddingLeft: '19px',
                        minWidth: '10px'
                      }}
                      style={{ marginRight: '10px', marginLeft: '10px' }}
                      startIcon={<ShoppingCartIcon />}></Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default ProductDetail;
