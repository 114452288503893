import React from 'react';
import ReactDOM from 'react-dom';
import store from './app/redux/store';
import { Provider } from 'react-redux';
import AppRouter from './app/navigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { AppContextProvider } from './context/AppContext';

const GlobalStylesOverride = () => (
  <GlobalStyles
    styles={{
      body: {
        '-webkit-font-smoothing': 'antialiased', // Improves WebKit browser rendering
        '-moz-osx-font-smoothing': 'grayscale', // Improves macOS rendering
        'text-rendering': 'optimizeLegibility', // Optimizes for better legibility
      },
    }}
  />
);

const theme = createTheme({
  typography: {
    fontFamily: '"Ranelte Extended Regular", sans-serif',
    fontWeightMedium: '800',
    fontWeightBold: 'bold',
    fontWeightRegular: '500'
  }, components: {
    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <GlobalStylesOverride />
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
