import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SnackBarComponent: React.FC<any> = () => {
    const [openSnackBar, setOpenSnackBar] = useState(true);
    const navigate = useNavigate();

    const handleViewCart = () => {
        setOpenSnackBar(false);
        navigate("/mycart");
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            style={{
                position: 'absolute',
                top: '75px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}
            open={openSnackBar}
            autoHideDuration={1000}
            message={
                <>
                    <Grid>
                        <Typography variant="body2" sx={{ flex: 1, color: '#fff', minWidth: '300px' }}>
                            <strong>Product added to cart successfully</strong>
                            <Button
                                size="small"
                                onClick={handleViewCart}
                                sx={{
                                    color: '#fff',
                                    ml: 20
                                }}>
                                View Cart
                            </Button>
                        </Typography>
                    </Grid>

                </>
            }
        />
    );
};

export default SnackBarComponent;