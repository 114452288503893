import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import OrderSummary from "../../components/OrderSummary/OrderSummary";
import { Container } from "@mui/material";
import { Delete } from "@mui/icons-material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./MyCart.css";
import { GET_CART_BY_CUSTOMER } from "../../redux/apis";
import Lottie from "react-lottie";
import * as animationData from "../../components/Lottie/noResults.json";
import { useNavigate } from "react-router-dom";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

// Define types for cart items and products
interface Product {
  productId: number;
  productName: string;
  salePrice: number;
  productDiscount?: number;
  imgSrc?: string;
}

interface CartItem {
  cartItemId: number;
  product: Product;
  quantity: number;
  totalPrice: number;
  unitPrice: number;
}

interface CartItemsProps {
  cartData: CartItem[];
  handleQuantityChange: any;
  removeItem: any;
}

const CartItems: React.FC<CartItemsProps> = ({ cartData, handleQuantityChange, removeItem }) => (
  <Box sx={{
    textAlign: 'center'
  }}>
    <Paper
      elevation={3}
      sx={{
        borderRadius: 2,
        padding: 2.5,
        marginTop: 5,
        minHeight: WIDTH < 400 ? "auto" : "30vh",
        maxHeight: WIDTH < 400 ? "auto" : "60vh",
        overflowY: WIDTH < 400 ? "visible" : "auto", // Adds scrolling if content overflows on larger screens
      }}
    >
      {cartData && cartData.length > 0 ? <>
        <List>
          {cartData.map((item: CartItem, index: any) => (
            <React.Fragment key={item.cartItemId}>
              <ListItem sx={{ display: "flex", alignItems: "center" }}>
                <Grid container spacing={2}>
                  <Grid item lg={1}>
                    <Avatar
                      src={require("../../../assets/Home/Cctv.png")}
                      sx={{ width: 60, height: 60, mr: 2 }}
                    />
                  </Grid>
                  <Grid item lg={7}>
                    <ListItemText
                      primary={<Typography component="span">
                        <strong>{item.product.productName}</strong>
                      </Typography>}
                      secondary={
                        <Box style={{ display: "grid" }}>
                          <Typography component="span">
                            <Typography component="span" color="error">
                              ₹{item.product.salePrice ? item.product.salePrice : "0.00"}
                            </Typography>
                          </Typography>
                          {/* <img
                            src={require("../../../assets/shippings.png")}
                            alt="shipping"
                          /> */}
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={4} lg={1.5} mt={1}>
                    <Typography component="span" color="error">
                      <strong>₹{item.totalPrice}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} lg={1.5}>
                    <TextField
                      defaultValue={item.quantity}
                      onInput={(e: any) => handleQuantityChange(index, e.target.value)}
                      sx={{
                        width: 80,
                        mr: 2,
                        "& .MuiOutlinedInput-root": {
                          width:
                            WIDTH < 400 ? (WIDTH * 25) / 100 : (WIDTH * 7) / 100,
                          "& .MuiInputBase-input": {
                            height: "5px",
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} lg={1}>
                    <Button color="error" onClick={() => removeItem(index)}>
                      <Delete />
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </> : <>
        <Box component="img"
          style={{ marginRight: WIDTH * 0.7 / 100, width: '15%', textAlign: 'center' }}
          src={require('../../../assets/Home/emptycart.jpg')} />
        <Typography style={{ textAlign: 'center', verticalAlign: 'middle' }}><strong>No Cart Items found</strong></Typography>
      </>}
    </Paper>

    <Paper
      elevation={3}
      sx={{ padding: 2.5, borderRadius: 2 }}
      style={{ marginTop: 30, textAlign: 'left' }}
    >
      <Box mt={2} display="flex" alignItems="center">
        <img src={require("../../../assets/shipp.png")} />
      </Box>
      <Typography
        sx={{
          ml: 1,
          fontSize: "12px",
          color: "#848484",
          marginTop: (HEIGHT * 0.2) / 100,
          marginLeft: (WIDTH * 0.3) / 100,
        }}
      >
        Well we human race are enchanted by fine artefact, so what’s better than
        Fashion? This would be one of the best ways to attract attention towards
        the adverse effects of manoeuvring nature’ by developing a whole new set
        of. Well we human race are enchanted by fine artefact, so what’s better
        than Fashion?
      </Typography>
    </Paper>
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12} lg={3} sx={{ textAlign: "center" }} className="fgt">
        <img src={require("../../../assets/secu.png")} alt="Secure Payment" />
        <Box style={{ textAlign: "left", marginLeft: "10px" }}>
          <Typography>Secure Payment</Typography>
          {/* <Typography style={{color: "#A9ACB0"}}>Have you ever finally just </Typography> */}
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} sx={{ textAlign: "center" }} className="fgt">
        <img src={require("../../../assets/suppo.png")} alt="24/7 Support" />
        <Box style={{ textAlign: "left", marginLeft: "10px" }}>
          <Typography>24/7 Support</Typography>
          {/* <Typography style={{color: "#A9ACB0"}}>Have you ever finally just </Typography> */}
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} sx={{ textAlign: "center" }} className="fgt">
        <img src={require("../../../assets/freesh.png")} alt="Free Shipping" />
        <Box style={{ textAlign: "left", marginLeft: "10px" }}>
          <Typography>Free Shipping</Typography>
          {/* <Typography style={{color: "#A9ACB0"}}>Have you ever finally just </Typography> */}
        </Box>
      </Grid>
      <Grid item xs={12} lg={3} sx={{ textAlign: "center" }} className="fgt">
        <Box
          style={{
            borderRadius: "100px",
            backgroundColor: "#DEE2E7",
            padding: 8,
            height: 32,
            width: 32,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img
            src={require("../../../assets/rep.png")}
            alt="2 Days Refundable"
          />
        </Box>
        <Box style={{ textAlign: "left", marginLeft: "10px" }}>
          <Typography>2 Days Refundable</Typography>
          {/* <Typography style={{color: "#A9ACB0"}}>Have you ever finally just </Typography> */}
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const MyCart = () => {
  const [cartData, setCartData] = useState([]);
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [customerId, setCustomerId] = useState<any>();
  const [sessionId, setSessionId] = useState<any>();
  const [token, setToken] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    setCustomerId(sessionStorage.getItem("customerId"));
    setToken(sessionStorage.getItem("token"));
    setSessionId(sessionStorage.getItem("sessionId"));

    // if (customerId && customerId != "") {
    //   getCustomerCart();
    // }
    const savedCart = sessionStorage.getItem("cart");
    if (savedCart) {
      const parsedCart: any = JSON.parse(savedCart);
      setCartData(parsedCart);
      setCartItems(parsedCart.cartItems);
    }
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleQuantityChange = (index: number, quantity: any) => {
    if (cartData) {
      let cartItem: CartItem = cartItems.at(index)!;
      cartItem.quantity = quantity;
      cartItem.totalPrice = cartItem.unitPrice * parseInt(quantity);

      const updatedItems: any = [...cartItems];
      updatedItems[index] = { ...updatedItems[index], cartItem };
      setCartItems(updatedItems);

      let updatedCartData = { ...cartData, "cartItems": updatedItems };
      setCartData(updatedCartData);
      sessionStorage.setItem("cart", JSON.stringify(updatedCartData));
    }
  }

  const removeItem = (index: number) => {
    if (cartData) {
      const updatedItems = cartItems.filter((_, i) => i !== index);
      setCartItems(updatedItems);
      let updatedCartData = { ...cartData, "cartItems": updatedItems };
      sessionStorage.setItem("cart", JSON.stringify(updatedCartData));
    }
  };


  const confirmOrder = () => {
    navigate("/confirm-order");
  };


  // const getCustomerCart = async () => {
  //   const response = await fetch(`${GET_CART_BY_CUSTOMER}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((error) => console.error("Fetch error:", error));
  // }

  return (
    <>
      <Header />
      <>
        <Box
          style={{ marginLeft: (WIDTH * 2) / 100, marginRight: (WIDTH * 2) / 100 }}
        >
          <Typography><strong>My Cart ({cartItems ? cartItems.length : 0} items)</strong></Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={cartItems && cartItems.length > 0 ? 8 : 12}>
              <CartItems cartData={cartItems} handleQuantityChange={handleQuantityChange} removeItem={removeItem} />
            </Grid>
            {cartItems && cartItems.length > 0 && <Grid item xs={12} md={4}>
              <OrderSummary cartData={cartItems} buttonText={"CONFIRM YOUR ORDER"} handleNavigation={confirmOrder} showCombo={true} />
            </Grid>
            }
          </Grid>

        </Box>
        <Footer />
      </>
    </>
  );
};

export default MyCart;
