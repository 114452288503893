import { Accordion, AccordionSummary, Grid, Typography, AccordionDetails, Box, TextField, Button, FormControlLabel, Checkbox } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GET_CURRENT_CUSTOMER, GET_OTP, VERIFY_OTP } from "../../redux/apis";
import { useReducer, useState } from "react";

import "./login.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from "../../redux/constants";
import { rootReducer } from "../../redux/reducers";
import { loginFailure, loginSuccess } from "../../redux/authReducer";
import { useDispatch } from "react-redux";

const Login = () => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [showOtpMsg, setShowOtpMsg] = useState(false);
    const [otp, setOtp] = useState("");
    const [name, setName] = useState("");
    const history = useNavigate();
    const dispatch = useDispatch();

    const handleSendOtp = async () => {
        const response = await fetch(`${GET_OTP}/${mobileNumber}`)
            .then((response) => response.json())
            .then((data: any) => {
                if (data.Status == 'SUCCESS') {
                    setIsOtpSent(true);
                    setShowOtpMsg(true);
                    setTimeout(() => {
                        setShowOtpMsg(false);
                    }, 2000);
                }
            })
            .catch((error) => console.error(error));
    };

    const getLogin = async () => {
        const response = await fetch(`${VERIFY_OTP}/${mobileNumber}/${otp}`)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.token) {
                    sessionStorage.setItem("token", data.token);
                    getMe(data.token);
                }
            })
            .catch((error) => console.error(error));
    };

    const getMe = async (val: any) => {
        const response = await fetch(`${GET_CURRENT_CUSTOMER}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${val}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(loginSuccess(data));
                sessionStorage.setItem("customerId", data.customerId);
                sessionStorage.setItem("firstName", data.firstName);
                sessionStorage.setItem("lastName", data.lastName);
                sessionStorage.setItem("email", data.email);
                sessionStorage.setItem("mobileNumber", data.mobileNumber);
                history("/");
            })
            .catch((error: any) => {
                console.error("Fetch error:", error);
                dispatch(loginFailure(error));
            });
    };

    return <>
        <Header />
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="90vh"
            bgcolor="#f5f5f5"
            px={2}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={4}
                bgcolor="#fff"
                borderRadius={2}
                boxShadow={3}
                width="80%"
                height={'80vh'}
            // maxWidth="400px"
            >
                <Box mb={2}>
                    <img
                        src={require('../../../assets/Home/Credenze.png')}
                        alt="OTP Verification"
                        style={{ width: '250px', height: 'auto' }}
                    />
                </Box>
                <Typography variant="h6" mb={2}>
                    <strong> LOGIN </strong>
                </Typography>
                <TextField
                    label="Enter your Mobile Number"
                    type="text"
                    variant="outlined"
                    style={{ width: '50%' }}
                    margin="normal"
                    value={mobileNumber}
                    onChange={(event) =>
                        setMobileNumber(event.target.value)
                    }
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSendOtp}
                >
                    Send OTP
                </Button>
                {showOtpMsg && <Typography mt={2} style={{ color: "green" }} variant="body2" >
                    <strong>OTP sent to your mobile number</strong>
                </Typography>}
                <TextField
                    label="Enter OTP"
                    type="text"
                    variant="outlined"
                    disabled={!isOtpSent}
                    style={{ width: '50%' }}
                    margin="normal"
                    value={otp}
                    onChange={(event) => setOtp(event.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '50%' }}
                    sx={{ mt: 2, mb: 2 }}
                    disabled={!isOtpSent}
                    onClick={getLogin}
                >
                    LOGIN
                </Button>
                {/* <FormControlLabel
                    control={<Checkbox />}
                    label="You'll be able to save your details to create an account later."
                    sx={{ alignSelf: "flex-start", mb: 1 }}
                />
                <FormControlLabel
                    control={<Checkbox />}
                    label="Keep me up to date on exclusive offers"
                    sx={{ alignSelf: "flex-start", mb: 2 }}
                /> */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        width: "auto",
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Don't have an account yet?{" "}
                        <Link
                            to="/otpverification"
                            color="primary"
                            style={{ marginLeft: "5px" }}
                        >
                            <strong>Register Free</strong>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Footer />
    </>
};

export default Login;