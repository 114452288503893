import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Divider,
  Select,
  MenuItem,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SelectChangeEvent,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNewCart } from "../../redux/cartSlice";
import SnackBarComponent from "../snackbar/snackbar";
import { debug } from "console";
import { addToCart } from "../../redux/cartUtils";

const HEIGHT = window.innerHeight;
const WIDTH = window.innerWidth;

class ProductRelations {
  "productRelationId": number;
  "productId": number;
  "relatedProductId": number;
  "slimProduct": {
    "productId": number;
    "productName": string;
    "productImageUrl": string;
    "mrpPrice": number;
    "taxPercent": number
  }
}

const PriceSection: React.FC<any> = ({ prop, pass, comboList, installationService, handleAddToCart }) => {
  const dispatch = useDispatch<any>();
  const history = useNavigate();
  const [productObj, setProductObj] = useState(pass);
  const [cartData, setCartData] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const savedCart = sessionStorage.getItem("cart");
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCartData(parsedCart);
      setCartItems(parsedCart.cartItems);
    }
  }, []);

  const handleAddCart = () => {
    const addCartResponse = addToCart(productObj, quantity, comboList, installationService);
    if (addCartResponse) {
      handleAddToCart();
    } else {
      history("/login");
    }
  }

  const [quantity, setQuantity] = useState(1);
  const [priceDetails, setPriceDetails] = useState({
    mrpPrice: 0,
    taxPercent: 0,
    saleWarranty: 0,
  });
  const totalPrice = (priceDetails.mrpPrice + (priceDetails.mrpPrice * priceDetails.taxPercent) / 100) * quantity;

  const handleQuantityChange = (event: SelectChangeEvent<number>) => {
    setQuantity(Number(event.target.value));
  };

  useEffect(() => {
    if (productObj) {
      setPriceDetails({
        mrpPrice: productObj.mrpPrice || 0,
        taxPercent: productObj.taxPercent || 0,
        saleWarranty: productObj.saleWarranty || 0,
      });
    }
  }, [productObj]);

  const handleBuyNow = () => {
    const custId = sessionStorage.getItem("customerId")
      ? Number(sessionStorage.getItem("customerId"))
      : null;
    const isCustomerLoggedIn = custId !== null;

    // Determine sessionId if customer is not logged in
    let sessionId = null;
    if (!isCustomerLoggedIn) {
      const sessionCount =
        Number(sessionStorage.getItem("sessionCount") || "0") + 1;
      sessionId = `session${sessionCount}`;
      sessionStorage.setItem("sessionCount", sessionCount.toString());
    }

    if (pass) {
      const addcart = {
        cartId: 0,
        sessionId: sessionId,
        cartItems: [
          {
            cartItemId: 0,
            product: {
              productId: pass.productId,
              productName: pass.productName,
              salePrice: pass.salePrice,
            },
            quantity: quantity,
            unitPrice: pass.mrpPrice,
            totalPrice: totalPrice,
          },
        ],
        customerId: custId,
      };

      // Save the addcart object to session storage as a JSON string
      sessionStorage.setItem("cart", JSON.stringify(addcart));
      prop("/mycart1");
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h4" color="error" gutterBottom>
          ₹{priceDetails.mrpPrice || "-"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="primary">
              GST{" "}
              {priceDetails.taxPercent ? `${priceDetails.taxPercent}%` : "-"}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <Typography variant="body2" color="textSecondary" mt={1}>
                (GST inclusive price)
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2" color="success" sx={{ ml: 1 }}>
              SaleWarranty {priceDetails.saleWarranty || "-"}
            </Typography>
            <Typography
              variant="h4"
              color="primary"
              sx={{
                fontWeight: "700",
              }}
            >
              ₹{totalPrice || "-"}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Select
              value={quantity}
              onChange={handleQuantityChange}
              fullWidth
              size="small"
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleBuyNow}
            >
              Buy Now
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ backgroundColor: "#E60303" }}
              onClick={handleAddCart}
            >
              Add to Cart
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
      </CardContent>
    </Box>
  );
};

const EssentialCombo: React.FC<any> = ({ productObj, addCombo, removeCombo }) => {
  const handleCheckbox = async (e: any, item: any, index: number) => {
    if (e == 'on') {
      let cartItem = {
        product: {
          productId: item.slimProduct.productId,
          productName: item.slimProduct.productName,
          salePrice: item.slimProduct.salePrice,
        },
        quantity: 1,
        unitPrice: item.slimProduct.mrpPrice,
        totalPrice: (item.slimProduct.mrpPrice + (item.slimProduct.mrpPrice * item.slimProduct.taxPercent) / 100),
      };
      addCombo(cartItem);
    } else {
      removeCombo(index);
    }
  };
  return <>
    <Typography variant="h6">Essential Combo</Typography>
    <List>
      {productObj &&
        productObj.productRelations &&
        productObj.productRelations.length !== 0 ? (
        productObj.productRelations.map(
          (
            item: ProductRelations,
            index: number
          ) => (
            <ListItem style={{
              marginLeft: (-WIDTH * 1.5) / 100,
              paddingLeft: 0,
              paddingTop: 0
            }}>
              <Checkbox onClick={(e: any) => handleCheckbox(e.target.value, item, index)} />
              <ListItemAvatar>
                <Avatar src={item.slimProduct.productImageUrl} />{" "}
              </ListItemAvatar>
              <ListItemText sx={{
                "& .MuiListItemText-primary": {
                  fontSize: '12px',
                },
                "& .MuiListItemText-secondary": {
                  fontSize: '10px',
                }
              }}
                primary={item.slimProduct.productName}
                secondary={item.slimProduct.mrpPrice ? "₹" + item.slimProduct.mrpPrice : "₹0.00"}
              />
            </ListItem>
          )
        )
      ) : ""}
    </List>

  </>
};

const InstallationServices: React.FC<any> = ({ productObj, setInstallationService }) => {
  const handleCheckbox = async (e: any) => {
    if (e == 'on') {
      let cartItem = {
        product: {
          productId: productObj.serviceProduct.productId,
          productName: productObj.serviceProduct.productName,
          salePrice: productObj.serviceProduct.salePrice,
        },
        quantity: 1,
        unitPrice: productObj.serviceProduct.mrpPrice,
        totalPrice: (productObj.serviceProduct.mrpPrice + (productObj.serviceProduct.mrpPrice * productObj.serviceProduct.taxPercent) / 100),
      };
      setInstallationService(cartItem);
    } else {
      setInstallationService(null);
    }
  };
  return <>
    <Typography variant="h6">Installation Services</Typography>
    <List>
      {productObj &&
        productObj.serviceProduct ? (
        <ListItem style={{
          marginLeft: (-WIDTH * 1.5) / 100,
          paddingLeft: 0,
          paddingTop: 0
        }}>
          <Checkbox onClick={(e: any) => handleCheckbox(e.target.value)} />
          <ListItemAvatar>
            <Avatar src={productObj.serviceProduct.productImageUrl} />{" "}
            {/* Replace with actual image URL */}
          </ListItemAvatar>
          <ListItemText sx={{
            "& .MuiListItemText-primary": {
              fontSize: '12px',
            },
            "& .MuiListItemText-secondary": {
              fontSize: '10px',
            }
          }}
            primary={productObj.serviceProduct.productName}
            secondary={productObj.serviceProduct.mrpPrice ? "₹" + productObj.serviceProduct.mrpPrice : "₹0.00"}
          />
        </ListItem>
      ) : ""}
    </List>

  </>
};

const AddToCartButton = () => (
  <Box mt={4}>
    <Button variant="contained" color="primary" fullWidth>
      Add Selected Items to Cart
    </Button>
  </Box>
);

const DetailPageSide: React.FC<{ pass: any }> = ({ pass }) => {
  useEffect(() => {
  }, []);

  const history = useNavigate();

  const [comboList, setComboList] = useState<any[]>([]);
  const addCombo = async (cartItem: any) => {
    setComboList([...comboList, cartItem]);
  }

  const removeCombo = async (index: number) => {
    const updatedList = comboList.filter((_, i) => i !== index);
    setComboList(updatedList);
  }

  const [installationService, setInstallationService] = useState<any>();

  const handleNavigation = (path: string) => {
    history(path);
  };

  const [open, setOpen] = useState(false);
  const handleAddToCart = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2500);
  }

  return WIDTH < 400 ? (
    <Box sx={{ py: 4 }} maxWidth="md">
      <Card
        sx={{ mb: 4 }}
        style={{ padding: (WIDTH * 2) / 100, borderRadius: 10 }}
      >
        <PriceSection prop={handleNavigation} pass={pass} comboList={comboList} installationService={installationService}
          handleAddToCart={handleAddToCart} />
        {pass && pass.productRelations && pass.productRelations.length > 0 &&
          <EssentialCombo addCombo={addCombo} removeCombo={removeCombo} productObj={pass} />
        }
        {pass && pass.serviceProduct &&
          <InstallationServices productObj={pass} setInstallationService={setInstallationService} />
        }
        {open && <SnackBarComponent />}
        {/* <AddToCartButton /> */}
      </Card>
    </Box>
  ) : (
    <Container sx={{ py: 4 }} maxWidth="md">
      <Card
        sx={{ mb: 4 }}
        style={{ padding: (WIDTH * 2) / 100, borderRadius: 10 }}
      >
        <PriceSection prop={handleNavigation} pass={pass} comboList={comboList} installationService={installationService}
          handleAddToCart={handleAddToCart} />
        {pass && pass.productRelations && pass.productRelations.length > 0 &&
          <EssentialCombo addCombo={addCombo} removeCombo={removeCombo} productObj={pass} />
        }
        {pass && pass.serviceProduct &&
          <InstallationServices productObj={pass} setInstallationService={setInstallationService} />
        }
        {open && <SnackBarComponent />}
        {/* <AddToCartButton /> */}
      </Card>
    </Container>
  );
};

export default DetailPageSide;
